import {
  FirebaseApp,
  FirebaseOptions,
  getApps,
  initializeApp,
} from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDqUMdNs7iz2EKCfTP5_jAAKiZahjIyvIM',
  authDomain: 'procura-dashboard.firebaseapp.com',
  projectId: 'procura-dashboard',
  storageBucket: 'procura-dashboard.appspot.com',
  messagingSenderId: '35502671478',
  appId: '1:35502671478:web:d1fcd1b205903df3dc4157',
  measurementId: 'G-YPS7VSBY2T',
} as FirebaseOptions;

const firebaseApp: FirebaseApp =
  getApps().length > 0 ? getApps()[0] : initializeApp(firebaseConfig);
const firestore = () => getFirestore(firebaseApp, 'prc-connect');
const messaging = () => getMessaging(firebaseApp);

export { firebaseApp, firestore, messaging };
