import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  HStack,
  IconButton,
  Stack,
  useToast,
  Link as ChakraLink,
} from '@chakra-ui/react';
import Link from 'next/link';
import { FiX } from 'react-icons/fi';

export default function useSmartToast(): SmartToastProps {
  const toast = useToast();

  const success = (description: string) => {
    toast({
      title: 'Success',
      description,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const error = (error = null, defaultMessage: string) => {
    toast({
      title: 'Error',
      description: defaultMessage || 'An error occurred.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const info = ({ title = 'Info', message, redirectLink = null }) => {
    toast({
      title: title,
      position: 'bottom-right',
      description: message,
      status: 'info',
      colorScheme: 'orange',
      variant: 'subtle',
      duration: 5000,
      isClosable: true,
      render: ({ onClose }) => (
        <Alert
          status='info'
          display='flex'
          alignItems='flex-start'
          justifyContent='space-between'
          rounded='md'
        >
          <HStack>
            <AlertIcon />
            <Stack gap='0' py='0'>
              <AlertTitle>{title}</AlertTitle>
              <AlertDescription>
                {message}{' '}
                {redirectLink && (
                  <ChakraLink
                    as={Link}
                    href={redirectLink}
                    target='_blank'
                    textDecoration={'underline'}
                  >
                    Click to view
                  </ChakraLink>
                )}
              </AlertDescription>
            </Stack>
          </HStack>

          <IconButton
            aria-label='Close'
            onClick={onClose}
            icon={<FiX />}
            size='sm'
            variant='ghost'
            colorScheme='gray'
          />
        </Alert>
      ),
    });
  };

  return {
    success,
    error,
    info,
  };
}

interface SmartToastProps {
  success: (message: string) => void;
  error: (error, defaultMessage: string) => void;
  info: (params: {
    title?: string;
    message: string;
    redirectLink?: string | null;
  }) => void;
}
