import axiosClient from '../utils/axios';

export const authLogin = async (data: any) => {
  const res = await axiosClient.post('/auth/login', data);
  return res.data;
};

export const requestForgotPassword = async data => {
  const res = await axiosClient.post('/auth/forgot-password/request', data);
  return res.data;
};

export const resetPassword = async (data: any) => {
  const res = await axiosClient.post('/auth/forgot-password/reset', data);
  return res.data;
};

export const refreshToken = (token: string, refreshToken: string) => {
  return axiosClient.get('/auth/refresh', {
    headers: {
      Authorization: `Bearer ${token}`,
      'refresh-token': refreshToken,
    },
  });
};

export const sendFCMToken = (token: string) => {
  console.log(`Sending token: ${token}`);

  return axiosClient.post('/auth/fcm-token', {
    fcmToken: token,
  });
};

export const checkExpiry = (token: string, refreshToken: string) => {
  return axiosClient.get('/auth/check-expiry', {
    headers: {
      Authorization: `Bearer ${token}`,
      'refresh-token': refreshToken,
    },
  });
};
