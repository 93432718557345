import { useEffect, useState } from 'react';

const useNotificationPermissionStatus = () => {
  const [permission, setPermission] =
    useState<NotificationPermission>('default');

  useEffect(() => {
    // Check initial permission
    const initialPermission = Notification.permission;
    setPermission(initialPermission);

    // Only attempt to request permission if it's not already granted or denied
    const requestPermissionSafely = () => {
      if (initialPermission === 'default') {
        Notification.requestPermission().then(newPermission => {
          setPermission(newPermission);
        });
      }
    };

    // Add an event listener for a user interaction
    const addPermissionRequestListener = () => {
      document.addEventListener('click', requestPermissionSafely, {
        once: true,
      });
    };

    addPermissionRequestListener();

    // Optional: Handle permission changes
    const handlePermissionChange = () => {
      setPermission(Notification.permission);
    };

    navigator.permissions
      .query({ name: 'notifications' })
      .then(notificationPerm => {
        notificationPerm.onchange = handlePermissionChange;
      });
  }, []);

  return permission;
};

export default useNotificationPermissionStatus;
