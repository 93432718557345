import {
  Avatar,
  Badge,
  Box,
  Card,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaFileInvoice } from 'react-icons/fa';
import {
  FiChevronDown,
  FiLogOut,
  FiMenu,
  FiShoppingBag,
  FiShoppingCart,
} from 'react-icons/fi';
import { useAuth } from '../../hooks/AuthContext';
import { useCart } from '../../hooks/CartContext';
import { isDemoEnv } from '../../utils/utils';
import CalendarPanel from './CalendarPanel';
import CheckAuth from './CheckAuth';
import NotificationsButton from './NotificationsButton';
import QuickSearch from './QuickSearch';

interface MobileProps extends FlexProps {
  onOpen: () => void;
  useNewLayout: boolean;
}

export default function MobileNav({
  onOpen,
  useNewLayout,
  ...rest
}: MobileProps) {
  const { user, logout } = useAuth();
  const { cart, setIsCartOpen, isCartOpen } = useCart();
  const apps: any = [
    {
      name: 'Shop',
      href: 'https://shop.procurabusiness.com',
      icon: FiShoppingBag,
      roles: ['admin', 'vendor', 'client'],
    },
    {
      name: 'Quotation Tool',
      href: '/tools/quotation',
      icon: FaFileInvoice,
      roles: ['admin'],
      permissions: ['quotations.manage'],
    },
  ];
  const isMobile = useMediaQuery('(max-width: 1080px)')[0];
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 32) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='24'
      width={{ sm: '100%', md: 'fit-content' }}
      alignItems='center'
      borderBottomWidth='0px'
      borderBottomColor='gray.200'
      justifyContent={{ sm: 'space-between', base: 'end' }}
      position='fixed'
      top={isDemoEnv() ? '8' : '0'}
      right={0}
      zIndex='500'
      transition='all 0.25s'
      bg={isMobile && isScrolled ? 'rgb(255 255 255 / 80%)' : 'transparent'}
      backdropFilter={isMobile && isScrolled ? 'blur(10px)' : 'none'}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        colorScheme={useNewLayout && !isScrolled ? 'white' : 'orange'}
        icon={<FiMenu />}
        color={useNewLayout && !isScrolled ? 'white' : 'inherit'}
      />

      <HStack>
        <a
          href='https://procurabusiness.com'
          target='_blank'
          rel='noreferrer noopener'
        >
          <Image
            alt='Procura Logo'
            height='64px'
            py='3'
            src='/images/brand-logo.png'
            display={{ base: 'flex', md: 'none' }}
            filter={
              isMobile && useNewLayout && !isScrolled
                ? 'invert(1) brightness(10000)'
                : 'none'
            }
          />
        </a>
      </HStack>

      <Card
        px='4'
        py='0'
        rounded='full'
        bg={{ base: 'transparent', md: 'white' }}
        {...(isMobile && useNewLayout ? { boxShadow: 'none' } : {})}
      >
        <HStack spacing={{ base: '0', md: '2' }}>
          <QuickSearch />
          <CalendarPanel />
          <NotificationsButton />
          {/* <Menu>
            <MenuButton
              as={IconButton}
              icon={<FiGrid />}
              variant='ghost'
              aria-label='Apps'
              colorScheme='gray'
              display={{ base: 'none', md: 'flex' }}
            />
            <MenuList
              bg={'white'}
              borderColor={'gray.200'}
              py='0'
              overflow='hidden'
            >
              <SimpleGrid
                columns={2}
                spacing='1px'
                px='0'
                py='0'
                borderBottom='solid 1px'
                borderColor='gray.200'
              >
                {apps
                  .filter(app => app.roles.includes(user.type))
                  .filter(link => {
                    if (user.type !== 'admin') return true;
                    if (user.permissions?.includes('superadmin')) return true;
                    if (link.permissions) {
                      if (
                        !link.permissions.some(permission =>
                          user.permissions.includes(permission),
                        )
                      )
                        return false;
                    }
                    return true;
                  })
                  .map((app, index) => (
                    <Stack
                      as={Link}
                      href={app.href}
                      key={index}
                      alignItems='center'
                      justify='center'
                      w='full'
                      p='4'
                      gap='4'
                      transition='all 0.25s'
                      _hover={{
                        bg: 'gray.100',
                      }}
                      target='_blank'
                    >
                      <Text fontSize='32px' color='procura-blue'>
                        <app.icon />
                      </Text>
                      <Text fontSize='sm' fontWeight='medium'>
                        {app.name}
                      </Text>
                    </Stack>
                  ))}
              </SimpleGrid>
            </MenuList>
          </Menu> */}

          <CheckAuth type='client'>
            <Box
              position='relative'
              display={{ base: 'none', md: 'flex' }}
              alignItems='center'
            >
              <IconButton
                icon={<FiShoppingCart />}
                variant='ghost'
                aria-label='Your Cart'
                className='cart-ignore'
                colorScheme='gray'
                display={{ base: 'none', md: 'flex' }}
                onClick={() => {
                  setIsCartOpen(current => !current);
                }}
              />
              {cart.length > 0 && (
                <Badge
                  colorScheme='orange'
                  rounded='full'
                  position='absolute'
                  bottom='0'
                  right='0'
                  pointerEvents='none'
                >
                  {cart.length > 9 ? '9+' : cart.length}
                </Badge>
              )}
            </Box>
          </CheckAuth>

          <Flex alignItems={'center'} pl={{ base: 0, md: 4 }}>
            <Menu>
              <MenuButton
                py={2}
                transition='all 0.3s'
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Avatar
                    size={'sm'}
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                      user?.name,
                    )}&background=random`}
                  />
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                py='0'
                overflow='hidden'
              >
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  px='4'
                  py='3'
                  borderBottom='solid 1px'
                  borderColor='gray.200'
                >
                  <Text fontSize='sm' color='gray.800' fontWeight='semibold'>
                    {user?.name}
                  </Text>
                  <Text fontSize='xs' color='gray.600'>
                    {user?.email}
                  </Text>
                </VStack>
                <MenuItem
                  onClick={logout}
                  px='4'
                  py='3'
                  fontWeight='medium'
                  fontSize='sm'
                  icon={<FiLogOut />}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Card>
    </Flex>
  );
}
