import { cardAnatomy, inputAnatomy } from '@chakra-ui/anatomy';
import {
  ChakraProvider,
  LightMode,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Head from 'next/head';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import 'react-tooltip/dist/react-tooltip.css';
import { AuthProvider } from '@/hooks/AuthContext';
import { CartProvider } from '@/hooks/CartContext';
import { ChatProvider } from '@/hooks/ChatContext';
import { MetadataProvider } from '@/hooks/MetadataContext';
import { PageTitleProvider } from '@/hooks/PageTitleHook';
import Layout from '@/components/common/Layout';
import '../../styles/globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

const { definePartsStyle } = createMultiStyleConfigHelpers(inputAnatomy.keys);
const cardStyler = createMultiStyleConfigHelpers(cardAnatomy.keys);
const theme = extendTheme(
  {
    components: {
      FormLabel: {
        baseStyle: {
          fontSize: 'sm',
          fontWeight: 'medium',
          color: 'gray.600',
        },
      },
      Select: {
        defaultProps: {
          focusBorderColor: 'orange.400',
        },
      },
      Stepper: {
        baseStyle: {
          indicator: {
            bg: 'procura-blue',
            borderRadius: 'full',
            transition: 'all 0.25s',
            color: 'white',
            fontSize: 'sm',
            fontWeight: 'semibold',
            '&[data-status=active]': {
              border: '2px solid #f79b1c',
            },
            '&[data-status=complete]': {
              background: '#f79b1c',
            },
          },
          separator: {
            '&[data-status=complete]': {
              background: '#221549',
            },
          },
          number: {
            color: 'white',
            fontSize: 'sm',
            fontWeight: 'semibold',
          },
          step: {
            color: 'gray.400',
          },
        },
      },
      Button: {
        baseStyle: {
          borderRadius: 'md',
        },
        variants: {
          procura: {
            backgroundColor: 'procura-blue',
            color: 'white',
            rounded: 'md',
            paddingY: '1.1rem',
            transition: 'all 0.25s',
            fontSize: 'base',
            borderBottom: '0.2rem solid',
            borderBottomColor: 'orange.300',
            _hover: {
              backgroundColor: 'orange.400',
              color: 'procura-blue',
              shadow: 'lg',
              _disabled: {
                color: 'white',
                backgroundColor: 'procura-blue',
                transform: 'scale(1)',
              },
            },
            _active: {
              backgroundColor: 'orange.400',
              color: 'procura-blue',
              transform: 'scale(0.975)',
              _disabled: {
                color: 'white',
                backgroundColor: 'procura-blue',
                transform: 'scale(1)',
              },
            },
            _focus: {
              ringColor: 'rgba(13, 8, 77, 0.5)',
              ring: '2px',
              ringOffset: '2px',
            },
          },
          exports: {
            color: 'green',
            rounded: 'md',
            paddingY: '1.1rem',
            fontSize: 'base',
            border: '1px solid',
            borderColor: 'green.300',
            _hover: {
              backgroundColor: 'green.100',
              _disabled: {
                color: 'white',
                backgroundColor: 'green',
              },
            },
            _active: {
              backgroundColor: 'green.400',
              color: 'green',
              transform: 'scale(0.975)',
              _disabled: {
                color: 'white',
                backgroundColor: 'green',
              },
            },
            _focus: {
              ringColor: 'green',
              ring: '2px',
              ringOffset: '2px',
            },
          },
          danger: {
            color: 'white',
            backgroundColor: '#ed1212',
            rounded: 'md',
            paddingY: '1.1rem',
            fontSize: 'base',
            border: '1px solid',
            _hover: {
              backgroundColor: '#f14141',
              _disabled: {
                color: 'white',
                backgroundColor: '#ed1212',
              },
            },
            _active: {
              backgroundColor: '#f47171',
              transform: 'scale(0.975)',
              _disabled: {
                color: 'white',
                backgroundColor: '#ed1212',
              },
            },
            _focus: {
              ringColor: 'red',
              ring: '2px',
              ringOffset: '2px',
            },
          },
        },
      },
      Input: {
        baseStyle: definePartsStyle({
          field: {
            borderRadius: 'md',
            fontSize: 'base',
            fontWeight: 'medium',
            _focus: {
              borderColor: 'orange.400',
              boxShadow: 'none',
            },
            _placeholder: {
              color: 'gray.500',
            },
          },
          element: {
            color: 'gray.500',
            _focus: {
              color: 'orange.400',
            },
          },
        }),
        defaultProps: {
          focusBorderColor: 'orange.400',
        },
      },
      Textarea: {
        baseStyle: definePartsStyle({
          field: {
            borderRadius: 'md',
            fontSize: 'base',
            fontWeight: 'medium',
            _focus: {
              borderColor: 'orange.400',
              boxShadow: 'none',
            },
            _placeholder: {
              color: 'gray.500',
            },
          },
          element: {
            color: 'gray.500',
            _focus: {
              color: 'orange.400',
            },
          },
        }),
        defaultProps: {
          focusBorderColor: 'orange.400',
        },
      },
      Tabs: {
        defaultProps: {
          fontSize: 'xs',
        },
      },
      Modal: {
        baseStyle: {
          dialog: {
            borderRadius: '2xl',
          },
        },
      },
      Card: cardStyler.defineMultiStyleConfig({
        baseStyle: cardStyler.definePartsStyle({
          container: {
            shadow: 'base',
            px: '6',
            py: '4',
            rounded: 'xl',
          },
        }),
      }),
    },
    colors: {
      'procura-blue': '#0d084d',
    },
  },
  withDefaultColorScheme({
    colorScheme: 'orange',
    components: ['Input', 'Button', 'Checkbox'],
  }),
);

function MyApp({ Component, pageProps }) {
  const getLayout =
    Component.getLayout ||
    (page => {
      return (
        <Layout
          layoutParams={
            Component.getLayoutParams ? Component.getLayoutParams() : {}
          }
        >
          {page}
        </Layout>
      );
    });

  return (
    <NuqsAdapter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <MetadataProvider>
            <CartProvider>
              <ChatProvider>
                <ChakraProvider theme={theme}>
                  <Head>
                    <title>Procura Connect</title>
                    <link
                      rel='apple-touch-icon'
                      sizes='180x180'
                      href='/apple-touch-icon.png'
                    />
                    <link
                      rel='icon'
                      type='image/png'
                      sizes='32x32'
                      href='/favicon-32x32.png'
                    />
                    <link
                      rel='icon'
                      type='image/png'
                      sizes='16x16'
                      href='/favicon-16x16.png'
                    />
                    <link rel='manifest' href='/site.webmanifest' />
                    <link
                      rel='mask-icon'
                      href='/safari-pinned-tab.svg'
                      color='#5bbad5'
                    />
                    <meta name='msapplication-TileColor' content='#da532c' />
                    <meta name='theme-color' content='#ffffff' />
                  </Head>
                  <LightMode>
                    <PageTitleProvider>
                      {getLayout(<Component {...pageProps} />)}
                      {process.env.NEXT_PUBLIC_NODE_ENV === 'local' && (
                        <ReactQueryDevtools
                          initialIsOpen={false}
                          buttonPosition='bottom-left'
                        />
                      )}
                    </PageTitleProvider>
                  </LightMode>
                </ChakraProvider>
              </ChatProvider>
            </CartProvider>
          </MetadataProvider>
        </AuthProvider>
      </QueryClientProvider>
    </NuqsAdapter>
  );
}

export default MyApp;
