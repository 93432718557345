import {
  Avatar,
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';
import { FiBell, FiBriefcase, FiShoppingCart, FiUser } from 'react-icons/fi';
import {
  getNotificationLastSeen,
  listNotifications,
  setNotificationLastSeen,
} from '@/controllers/notification.controller';
import useFCM from '@/hooks/useFCM';

export default function NotificationsButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notifications, setNotifications] = useState([]);
  const [lastSeenAt, setLastSeenAt] = useState(new Date());
  const {} = useFCM();

  const fetchNotifications = async () => {
    try {
      const data = await listNotifications();
      setNotifications(data.results);
    } catch (error) {
      console.error(error);
    }
  };

  const setLastSeenServer = async () => {
    try {
      await setNotificationLastSeen();
    } catch (error) {
      console.error(error);
    }
  };

  const getLastSeenServer = async () => {
    try {
      const data = await getNotificationLastSeen();
      setLastSeenAt(data.lastSeen);

      if (moment().diff(moment(data.lastSeen), 'minutes') > 0)
        await fetchNotifications();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'local') {
      const interval = setInterval(() => {
        getLastSeenServer();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, []);

  const notificationCount =
    notifications?.filter(notification => {
      return moment(notification.timestamp).isAfter(lastSeenAt);
    }).length || 0;

  return (
    <Box position='relative' display={{ base: 'none', md: 'flex' }}>
      <IconButton
        icon={<FiBell />}
        variant='ghost'
        aria-label='Notifications'
        colorScheme='gray'
        display={{ base: 'none', md: 'flex' }}
        onClick={() => {
          onOpen();
        }}
      />
      {notificationCount > 0 && (
        <Badge
          colorScheme='orange'
          rounded='full'
          position='absolute'
          bottom='0'
          right='0'
          pointerEvents='none'
        >
          {notificationCount > 9 ? '9+' : notificationCount}
        </Badge>
      )}
      <NotificationsDrawer
        isOpen={isOpen}
        onClose={() => {
          setLastSeenAt(new Date());
          setLastSeenServer();
          onClose();
        }}
        notifications={notifications}
        lastSeenAt={lastSeenAt}
      />
    </Box>
  );
}

function NotificationsDrawer({ isOpen, onClose, notifications, lastSeenAt }) {
  const seenNotifications = notifications.filter(notification =>
    moment(notification.timestamp).isSameOrBefore(lastSeenAt),
  );
  const unseenNotifications = notifications.filter(notification =>
    moment(notification.timestamp).isAfter(lastSeenAt),
  );

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size='sm'>
      <DrawerOverlay />
      <DrawerContent roundedLeft='2xl'>
        <DrawerCloseButton p='4' m='4' />
        <DrawerHeader borderBottom='solid 1px' borderBottomColor='gray.200'>
          <HStack px='2' py='2' spacing='4'>
            <FiBell />
            <Text>Notifications</Text>
          </HStack>
        </DrawerHeader>
        <DrawerBody px='0' h='full'>
          <Stack h='full' overflowY='auto' overflowX='hidden' spacing='0'>
            {unseenNotifications.length > 0 && (
              <>
                <Text
                  fontSize='xs'
                  fontWeight='semibold'
                  color='gray.500'
                  px='4'
                  mt='3'
                  mb='2'
                  letterSpacing='wider'
                >
                  UNSEEN NOTIFICATIONS
                </Text>
                {unseenNotifications.map((notification, index) => (
                  <NotificationItem key={index} notification={notification} />
                ))}
              </>
            )}
            {seenNotifications.length > 0 && (
              <>
                <Text
                  fontSize='xs'
                  fontWeight='semibold'
                  color='gray.500'
                  px='4'
                  mt='3'
                  mb='2'
                  letterSpacing='wider'
                >
                  OLDER NOTIFICATIONS
                </Text>
                {seenNotifications.map((notification, index) => (
                  <NotificationItem key={index} notification={notification} />
                ))}
              </>
            )}
            {seenNotifications.length === 0 &&
              unseenNotifications.length === 0 && (
                <Text
                  fontSize='sm'
                  color='gray.500'
                  px='4'
                  mt='3'
                  mb='2'
                  textAlign='center'
                >
                  No notifications
                </Text>
              )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function NotificationItem({ notification }) {
  const types = {
    account: { icon: <FiUser fontSize='1rem' />, color: 'green.500' },
    order: { icon: <FiShoppingCart fontSize='0.8rem' />, color: 'orange.500' },
    order_request: {
      icon: <FaCartPlus fontSize='0.8rem' />,
      color: 'purple.500',
    },
    organization: { icon: <FiBriefcase fontSize='1rem' />, color: 'blue.500' },
  };

  return (
    <HStack
      as={notification.redirectLink ? Link : 'div'}
      {...(notification.redirectLink && {
        href: notification.redirectLink,
        passHref: true,
      })}
      spacing='4'
      borderBottom='solid 1px'
      borderBottomColor='gray.200'
      px='4'
      py='2'
      _hover={{ bg: 'gray.100' }}
      transition={'all 0.25s'}
      w='full'
    >
      <Avatar
        size='sm'
        bg={types[notification.type]?.color || 'gray.500'}
        icon={types[notification.type]?.icon || <FiBell />}
      />
      <Stack spacing='0.5' w='full'>
        <HStack spacing='2'>
          <Text fontWeight='medium' fontSize='sm' color='gray.800'>
            {notification.title}
          </Text>
          <Tooltip
            label={moment(notification.timestamp).format(
              'MMMM Do YYYY, h:mm: a',
            )}
          >
            <Text fontSize='xs' color='gray.500' ml='auto'>
              {moment(notification.timestamp).fromNow()}
            </Text>
          </Tooltip>
        </HStack>
        <Text fontSize='sm' color='gray.600'>
          {notification.message}
        </Text>
      </Stack>
    </HStack>
  );
}
