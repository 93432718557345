import axiosClient from '@/utils/axios';

type Address = {
  bnm: string; // Building Name
  st: string; // Street
  loc: string; // Locality
  bno: string; // Building Number
  dst: string; // District
  lt: string; // Latitude
  locality: string; // Locality Name
  pncd: string; // Pincode
  landMark: string; // Landmark
  stcd: string; // State Code
  geocodelvl: string; // Geocode Level
  flno: string; // Floor Number
  lg: string; // Longitude
};

type PrincipalAddress = {
  addr: Address;
  ntr: string; // Nature of Business
};

type GSTDetails = {
  stjCd: string; // State Jurisdiction Code
  stj: string; // State Jurisdiction
  lgnm: string; // Legal Name
  dty: string; // Duty Type
  adadr: Address[]; // Additional Addresses
  cxdt: string; // Cancelation Date
  nba: string[]; // Nature of Business Activity
  gstin: string; // GST Identification Number
  lstupdt: string; // Last Update Date
  rgdt: string; // Registration Date
  ctb: string; // Constitution of Business
  pradr: PrincipalAddress; // Principal Address
  ctjCd: string; // Central Jurisdiction Code
  sts: string; // Status
  tradeNam: string; // Trade Name
  ctj: string; // Central Jurisdiction
  einvoiceStatus: string; // E-Invoice Status
};

export const validateGST = async (gstNumber: string) => {
  const response = await axiosClient.get<GSTDetails>(
    `/gsp/gst/validate/${gstNumber}`,
  );

  return response.data;
};
