import { MessagePayload, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { messaging } from '@/utils/firebase';
import useSmartToast from './SmartToast';
import useFCMToken from './useFCMToken';

const useFCM = () => {
  const fcmToken = useFCMToken();
  const [messages, setMessages] = useState<MessagePayload[]>([]);
  const toast = useSmartToast();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const unsubscribe = onMessage(messaging(), payload => {
        /* Toast Message Popup */
        toast.info({
          title: payload.data.title,
          message: payload.data.body,
          redirectLink: payload.data.redirectLink,
        });

        setMessages(messages => [...messages, payload]);
      });
      return () => unsubscribe();
    }
  }, [fcmToken]);
  return { fcmToken, messages };
};

export default useFCM;
