import {
  Avatar,
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { collection, onSnapshot } from 'firebase/firestore';
import moment from 'moment';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { useAuth } from '@/hooks/AuthContext';
import { firestore } from '@/utils/firebase';

export default function CalendarPanel() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [followUps, setFollowUps] = useState({});
  const { user } = useAuth();

  /* Firestore configs */
  useEffect(() => {
    if (!user) return;

    const docRef = collection(firestore(), 'calendar', user.id, 'follow-ups');

    const unsubscribe = onSnapshot(docRef, snapShot => {
      const group = {};

      snapShot.docs.map(doc => {
        const data = doc.data();
        const groupKey = moment(data.scheduledAt, 'YYYY-MM-DDTHH:mm').format(
          'DD/MM/YYYY',
        );

        if (!group[groupKey]) group[groupKey] = [];
        group[groupKey].push(data);
      });

      setFollowUps(group);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Box position='relative' display={{ base: 'none', md: 'flex' }}>
      <IconButton
        icon={<FaCalendarAlt />}
        variant='ghost'
        aria-label={'Calendar'}
        colorScheme='gray'
        onClick={() => onOpen()}
      />
      {Object.keys(followUps).length > 0 && (
        <Badge
          colorScheme='orange'
          rounded='full'
          position='absolute'
          bottom='0'
          right='0'
          pointerEvents='none'
        >
          {Object.keys(followUps).reduce((acc, key) => {
            return acc + followUps[key].length;
          }, 0) > 9
            ? '9+'
            : Object.keys(followUps).reduce((acc, key) => {
                return acc + followUps[key].length;
              }, 0)}
        </Badge>
      )}
      <MeetingsDrawer
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        followUps={followUps}
      />
    </Box>
  );
}

function MeetingsDrawer({ isOpen, onClose, followUps }) {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size='sm'>
      <DrawerOverlay />
      <DrawerContent roundedLeft='2xl'>
        <DrawerCloseButton p='4' m='4' />
        <DrawerHeader borderBottom='solid 1px' borderBottomColor='gray.200'>
          <HStack px='2' py='2' spacing='4'>
            <FaCalendarAlt />
            <Text>Events</Text>
          </HStack>
        </DrawerHeader>
        <DrawerBody px='0' h='full'>
          <Stack
            h='full'
            overflowY='auto'
            overflowX='hidden'
            spacing='0'
            px='4'
          >
            {Object.keys(followUps)
              .sort(
                (a, b) =>
                  moment(a, 'DD/MM/YYYY').valueOf() -
                  moment(b, 'DD/MM/YYYY').valueOf(),
              )
              .map(key => {
                const meetings = followUps[key] || [];

                return (
                  <Stack key={key} pl='2'>
                    <Heading as='h6' size='base' fontWeight='semibold'>
                      {key === moment().format('DD/MM/YYYY') ? 'Today' : key}
                    </Heading>

                    <Stack pb='4'>
                      {meetings.map(meeting => (
                        <MeetingItem key={meeting.id} meeting={meeting} />
                      ))}
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function MeetingItem({ meeting }) {
  return (
    <HStack
      as={Link}
      href={`/crm/details/${meeting?.lead?.id}`}
      spacing='4'
      borderBottom='solid 1px'
      borderBottomColor='gray.200'
      px='4'
      py='2'
      _hover={{ bg: 'gray.100' }}
      transition={'all 0.25s'}
      w='full'
    >
      <Avatar
        size={'sm'}
        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
          meeting?.lead.name,
        )}&background=random`}
      />
      <Stack spacing='0.5' w='full'>
        <HStack spacing='2'>
          <Text fontWeight='medium' fontSize='sm' color='gray.800'>
            Follow up with {meeting?.lead.name}
          </Text>
          <Tooltip
            label={moment(meeting?.scheduledAt).format('MMMM Do YYYY, h:mm: a')}
          >
            <Text fontSize='xs' color='gray.500' ml='auto'>
              {moment().isAfter(moment(meeting?.scheduledAt))
                ? 'Ongoing'
                : moment(meeting?.scheduledAt).fromNow()}
            </Text>
          </Tooltip>
        </HStack>
        <Text fontSize='sm' color='gray.600'>
          Remark: {meeting.remark}
        </Text>
      </Stack>
    </HStack>
  );
}
