import { getToken, isSupported } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { sendFCMToken } from '@/controllers/auth.controller';
import { messaging } from '@/utils/firebase';
import { useAuth } from './AuthContext';
import useNotificationPermission from './useNotificationPermission';

const useFCMToken = () => {
  const permission = useNotificationPermission();
  const {} = useAuth();
  const [fcmToken, setFcmToken] = useState<string | null>(null);

  useEffect(() => {
    const retrieveToken = async () => {
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        if (permission === 'granted') {
          try {
            const isFCMSupported = await isSupported();

            if (!isFCMSupported) return;

            const token = await getToken(messaging(), {
              vapidKey:
                'BG-i1_23fITGRHlbp3VpHxoFMv5_Un1RqNbaCMsqOJ9a8xgX5vWfqHnxnMxiNaKli2Ho7YzZBDV3I7cEBpw-HHY',
            });

            if (token) await sendFCMToken(token);

            setFcmToken(token);
          } catch (error) {
            console.log('Firebase Error:', error);
            setFcmToken(null);
          }
        }
      }
    };
    retrieveToken();
  }, [permission]);

  return fcmToken;
};

export default useFCMToken;
